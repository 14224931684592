.heading-profile {
  font-size: 50px;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.shadow-effect-header {
  text-transform: uppercase;
  color: #fd2155;
  text-shadow: -2px 2px var(--primary-color), -2px 2px var(--primary-color),
    -2px 2px var(--primary-color);
}
.profile-role {
  text-transform: uppercase;
  font-size: 20px;
  color: grey;
  font-weight: lighter;
}

/* propfile image */

.profile-image-wrapper{
  font-size: 15px;
  word-spacing: 0.5ch;
  letter-spacing: 0.1ch;
}

.profile-image img {
  border-radius: 100px;
  border: 5px solid rgb(78, 78, 78);
  cursor: pointer;
}

.profile-image img:hover {
  transition: 1s ease-in
}

.about-me {
  margin: 3% 0;

}

.about-me p{
  word-spacing: 0.8ch;
  font-weight: lighter;
  font-family: var(--content-font-family);
}


.contact-cv {
  width: 100%;
  margin: 5% 0;
}

.contact-cv a {
  text-decoration: none;
  font-size: 1.2vw;
  outline: none;
  padding: 1.5% 4%;
  letter-spacing: 0.5ch;
}
.contact-cv a:nth-child(1) {
  background: none;
  margin-right: 3%;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  background-size: 200% 100%;
}

.contact-cv a:nth-child(2) {
  color: black;
  background: linear-gradient(to right, var(--primary-color) 100%, black 50%)
    left;
  background-size: 100% 200%;
}

.contact-cv a:nth-child(1):hover {
  /* background-color: var(--primary-color); */
  color: black;
  transition: 1s;
  background: linear-gradient(to right, var(--primary-color) 100%, black 50%)
    left;
  background-size: 100% 200%;
}

.contact-cv a:nth-child(2):hover {
  /* background-color: var(--primary-color); */

  transition: 1s;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  background: none;
  background-size: 200% 100%;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
    0 0 42px var(--primary-color), 0 0 82px var(--primary-color),
    0 0 92px var(--primary-color), 0 0 102px var(--primary-color),
    0 0 151px var(--primary-color);
}

/* Profile.jsx page css */
@media only screen and (max-width: 600px) {
  .heading-profile {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  /* Profile.jsx page css */

  .profile-role {
    font-size: 12px;
  }
  .about-me {
    font-size: 12px;
  }

  .contact-cv a {
    font-size: 2vw;
  }
}
