.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* left container */
.left-container {
 flex: 1;
  display: flex;
  flex-direction: row;
}

/* end of left container */

/* Nav bar container */
.nav-bar-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


.nav-links {
  text-decoration: none;
  color: white;
  font-size: 19px;
}

.nav-links:hover {
  color: #08fdd8;
  font-size: 20px;
}
.active > span {
  color: #08fdd8;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 5px;
}
.settings {
    cursor: pointer;
  }

/* end of nav bar container */

/* right container */
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  align-items: flex-end;
  padding-right: 2%;
  justify-content: center;
}


@media only screen and (max-width: 600px) {
 
   .nav-bar-container{
    flex: 3;
    justify-content: space-around;
    transition: 1s ease;
   }

   .right-container, .left-container{
    flex: 0;
    display: none;
   }
  }
  