.skills-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.skills-heading-container {
  margin-bottom: 35px;
}

.skills-heading-text span {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
}

.skills-heading-content p {
  word-spacing: 0.8ch;
  font-weight: lighter;
  font-family: var(--content-font-family);
}

.skills-category{
    margin-bottom: 50px;
}


.skills-category-gallery {
  display: grid;
  /* grid-template-columns: 10rem 10rem 10rem; */
  grid-gap: 1rem;
  grid-auto-flow: column;
}

.gallery-box:hover {
  box-shadow: none;
  transition: 1s ease;
}


.flip-box {
  background-color: transparent;
  width: auto;
  height: 120px;
  perspective: 1000px;
}

.flip-box-inner {
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  padding: 1%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid rgb(108, 108, 108);
  box-shadow: 3px 5px 2px #c5c5c5;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  justify-content: center;
}

.flip-box-front {
  display: grid;
}
.flip-box-back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-box-back span {
  color: white;
  font-size: 12px;
  font-weight: lighter;
  font-family: var(--content-font-family);
}

@media only screen and (max-width: 600px) {
  .skills-category-gallery {
    grid-gap: 2rem;
    grid-auto-flow: row;
  }
}
