.contact-wrapper {
  width: 50%;
  margin: auto;
  border: 1.5px solid white;
  text-align: center;
  height: auto;
  box-shadow: 10px 10px 1px var(--box-shadow-dark);
  padding: 1%;
  border-radius: 5px 5px 0 0;
}

.contact-heading-text {
  font-size: var(--heading-font-size);
  color: var(--primary-color);
  margin: 2%;
}
.input-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1%;
}

.validation-error-msg {
  margin-left: 2.5%;
  font-size: 12px;
  font-weight: lighter;
  font-family: var(--content-font-family);
  color: #ff695f;
}

.inputs {
  border: none;
  outline: none;
  border-radius: 5px 5px 0 0;
  background: var(--darktheme-nav-background-color);
  padding: 10px;
  margin: 2.5%;
  justify-content: space-evenly;
  color: white;
  font-weight: lighter;
}



.inputs:focus {
  border-bottom: 2px solid var(--primary-color);
  color: white;
}

.btn-send-msg {
  background: none;
  border: 1px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  margin: 2.5%;
  background-size: 200% 100%;
  padding: 2%;
  color: var(--primary-color);
  text-transform: uppercase;
}

.btn-send-msg:hover {
  color: black;
  transition: 1s;
  background: linear-gradient(to right, var(--primary-color) 100%, black 50%)
    left;
  cursor: pointer;
}
.send-msg-loading-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-mobile-view{
  display: none;
  text-align: center;
  color: grey;
  font-size: 10px;
  padding-bottom: 1%;
  font-family: var(--content-font-family);
  font-style: italic;

}

@media only screen and (max-width: 600px) {
  .contact-wrapper {
    width: 100%;
    padding: 0%;
  }

  .inputs {
    margin: 4%;
  }
  .validation-error-msg {
    margin-left: 4%;
  }

  .btn-send-msg {
    margin: 4%;
    padding: 2%;
  }
  .info-mobile-view{
    display: block;
  }
}
