.work-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.work-heading-container {
  margin-bottom: 35px;
}

.work-heading-text span {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
}

.work-heading-content {
  background: var(--darktheme-nav-background-color);
  box-shadow: 10px 10px 1px var(--box-shadow-dark);
  padding: 2%;
  border-radius: 5px;
  margin-bottom: 2%;
}

.work-details {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.work-heading-content div:not(:last-child) {
  margin-bottom: 15px;
}

.work-heading-content div span {
  font-weight: lighter;
  font-family: var(--content-font-family);
}

.work-category {
  margin-bottom: 35px;
}

.work-category-gallery {
  display: grid;
  /* grid-template-columns: 10rem 10rem 10rem; */
  grid-gap: 1rem;
  grid-auto-flow: column;
}

.mySwiper {
  padding-bottom: 55px;
}

.gallery-box:hover {
  box-shadow: none;
  transition: 1s ease;
}


.flip-box {
  background-color: transparent;
  width: auto;
  height: 150px;
}

.flip-box-inner {
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  padding: 1%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid rgb(108, 108, 108);
  box-shadow: 3px 5px 2px #c5c5c5;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
 
  
 
  
}

.flip-box-front {
  /* margin: auto; */
  display: grid;
  justify-items:center;
  
}

.flip-box-front span{
  /* margin: auto; */
  font-weight: lighter;
  font-family: var(--content-font-family);
  
}
.flip-box-back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.flip-box-back span {
  color: white;
  font-size: 12px;
  font-weight: lighter;
  font-family: var(--content-font-family);
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .work-category-gallery {
    grid-gap: 2rem;
    grid-auto-flow: row;
  }
  .work-data-list {
    font-size: 2.8vw;
  }
}
