
/* width */
::-webkit-scrollbar {
    width: 12px;
    border-radius: 50px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 50px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
   
    background: var(--primary-color);
  }
  
  /* Buttons */
  ::-webkit-scrollbar-button:single-button {
    background-color: #bbbbbb;
    display: block;
    border-style: solid;
    height: 13px;
    width: 16px;
  }
  /* Up */
  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #555555 transparent;
  }
  
  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent var(--primary-color) transparent;
  }
  /* Down */
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 12px 12px 0 12px;
    border-color: #555555 transparent transparent transparent;
  }
  
  ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: var(--primary-color) transparent transparent transparent;
  }