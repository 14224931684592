@import url("https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Tilt+Warp&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Roboto+Mono&family=Shantell+Sans:wght@300&display=swap');

:root{
  /* Definding main colors */
  --primary-color: #52FBD8;
  --secondary-color:#F33459;
  --heading-font-size: 25px;
  --content-font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  /* Dark theme colors */
  --darktheme-nav-background-color: #3a3a3a;
  --darktheme-main-body-background-color:#1C1C1C;

  /* box shadow dark  */
  --box-shadow-dark: #262626;

  /* Light theme color */
  --lighttheme-nav-background-color: #b7b7b7;
  --lighttheme-main-body-background-color:#FFFFFF;

}
* {
  margin: 0;
  font-family: "Tilt Warp", cursive;
 
}

.App{
  width: 100%;
  display: flex;
  flex-direction: column;
}


/* Defiding theme classes */
.light-theme{
  background-color: var(--lighttheme-main-body-background-color);
  color: #000000;
  transition: 1s ease;
}

.dark-theme{
  background-color: var(--darktheme-main-body-background-color);
  color: #FFFFFF;
  transition: 1s ease;
}
.main-body{
  width: 90%;
  height: 90%;
  margin: 1% 5% 10% 5%;
  text-align: justify;
  text-justify: inter-word;
}

.mainbody-wrapper {
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  color: grey;
  font-family: "Cedarville Cursive", cursive;
  user-select: none;
  /* z-index: 1; */
}


/* End of main body */

/* nav bar component */
.nav-bar{
  width: 100%;
  height: 4%;
  padding: 1.5% 0%;
  bottom: 0;
  position: fixed;
}

.dark-nav-bar{
background: var(--darktheme-nav-background-color);

}

.light-nav-bar{
  background: var(--lighttheme-nav-background-color);

}

/* Social media container */
.sticky-socialmedia-container{

  position: fixed;
  top: 50%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1%;
}

.social-medias{
  font-size: 20px;
  color: white;
  text-decoration: none;

}
.social-medias:hover{
  color: var(--primary-color);
  transition: 1s ease-in;
}

/* sticky scroll arrow */
.sticky-scroll-arrow{
  margin-left: 0; 
  margin-right: auto;
  top: 80%;
  height: 40%;
  right: 1%;
  position: fixed;
 
}
.sticky-scroll-arrow button{
  background: none;
  outline: none;
  border: none;
  color: var(--secondary-color);
  cursor: pointer;
}

.sticky-scroll-arrow button:hover{
  animation: bouncescroll 1.5s infinite ;
}

@keyframes bouncescroll {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


@media only screen and (max-width: 600px) {
 
  /* App.js css */
  .sticky-socialmedia-container,.sticky-scroll-arrow   {
    display: none;
 
  }
}
