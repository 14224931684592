.education-wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
  
  .education-heading-container {
    margin-bottom: 35px;
  }
  
  .education-heading-text span {
    color: var(--primary-color);
    font-size: var(--heading-font-size);
  }
  

  .education-gallery > h1, h2, h3, h4, h5, h6, p {
    font-family: var(--content-font-family);
    font-weight: lighter;
  }

  .education-awards{
    background: rgb(205, 205, 205);
    padding: 5px;
    border-radius: 100px;
    color: black;
    font-style: italic;
    font-size: 10px;
    font-family: var(--content-font-family);
    text-align: left;
  }